var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 mb-1"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"background-color":_vm.search ? 'serenade' : '',"prepend-inner-icon":"mdi-magnify","outlined":"","hide-details":"","dense":"","clearable":"","placeholder":_vm._f("localize")('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.requestList,"loading":_vm.loading,"item-key":"id + transport_mode","loader-height":"2","options":_vm.options,"server-items-length":_vm.totalItems,"no-data-text":_vm._f("localize")('noDataAvailable'),"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.offer_reference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['offer_reference'] ? item['offer_reference'] : '--')+" ")]}},{key:"item.proposal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['proposal'])+" "),_c('i',{staticClass:"text-body-2"},[_vm._v(_vm._s(item['currency']))])]}},{key:"item.lead_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['lead_time'])+" "+_vm._s(_vm.leadTimeDays(item['lead_time']))+" ")]}},{key:"item.deadline_for_booking",fn:function(ref){
var item = ref.item;
return [(item['deadline_for_booking'].trim())?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatDate(item['deadline_for_booking']))}}):_c('div',[_vm._v("--")])]}},{key:"item.latest_collection_time",fn:function(ref){
var item = ref.item;
return [(item['latest_collection_time'].trim())?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatDate(item['latest_collection_time']))}}):_c('div',[_vm._v("--")])]}},{key:"item.valid_to",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatDate(item['valid_to']))}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.STATUS_COLORS[item.status],"size":"20"}},[_vm._v(_vm._s(_vm.STATUS_ICONS[item.status]))]),_c('span',[_vm._v(_vm._s(_vm._f("localize")(item.status)))])],1)]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comment)?_c('v-tooltip',{attrs:{"top":"","color":"black","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-comment")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.comment))])]):_vm._e(),(item['reopen_comment'])?_c('v-tooltip',{attrs:{"top":"","color":"black","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-comment")])]}}],null,true)},[_c('div',[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('reopenComment'))+":")])]),_vm._v(" "+_vm._s(item['reopen_comment'])+" ")])]):_vm._e()]}},{key:"item.attachments",fn:function(ref){
var item = ref.item;
return _vm._l((item['attachments']),function(fileData){return _c('v-tooltip',{key:fileData['attachment_name'],attrs:{"top":"","color":"black","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":fileData['attachment_link'],"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-file")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(fileData['attachment_name']))])])})}},{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.STATUSES.BOOKED)?_c('v-btn',{staticClass:"justify-center btn-fixed-width",attrs:{"rounded":"","fab":"","x-small":"","depressed":"","color":"success","to":{name: 'booking-summary', query: {request_id: _vm.requestId, proposal_id: item.id, mode: _vm.transportMode}}}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-book-open-variant")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('bookingSummaryOpen')))])])],1):_vm._e()]}},(_vm.isProposalsEditing)?{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.STATUSES.ACTION_REQUIRED)?_c('div',{staticClass:"text-center py-1"},[(_vm.confirms.includes(item.id))?_c('v-progress-circular',{attrs:{"indeterminate":"","width":1,"size":20}}):[_c('v-btn',{staticClass:"mb-1 middle-button",attrs:{"depressed":"","color":"success","block":"","lo":""},on:{"click":function($event){return _vm.confirmProposal(item.id)}}},[_vm._v(" "+_vm._s(_vm._f("localize")('confirmPrice'))+" ")]),_c('v-btn',{staticClass:"middle-button",attrs:{"depressed":"","color":"grey lighten-2","block":""},on:{"click":function($event){return _vm.editProposal(item)}}},[_vm._v(" "+_vm._s(_vm._f("localize")('updatePrice'))+" ")])]],2):_c('div',{staticClass:"text-right"},[_c('v-menu',{attrs:{"left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"45","tile":""}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editProposal(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('edit')))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.copyProposal(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('copy')))])],1)],1)],1)],1)]}}:null],null,true)}),_c('v-pagination',{staticClass:"py-6",attrs:{"color":"primary","length":_vm.totalPages,"total-visible":7,"disabled":_vm.loading},on:{"input":function($event){_vm.requestList = [];_vm.getProposals();}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }